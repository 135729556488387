import React, { useState, useEffect } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Star, CreditCard, Zap, Mail, Phone } from 'lucide-react';
import axios from 'axios';
import useCredits from '../hooks/useCredits';
import CreditAddModelBag from '../assets/icons/AddModelCreditSystem@3x.jpg';
import CreatShotCreditSystem from '../assets/icons/CreateShotCreditSystem@3x.png';
import UpscaleShotCreditSystem from '../assets/icons/UpscaleShotCreditSystem@3x.png';
import Loader from '../assets/icons/loader.svg';
const XPayView = () => {
  const [topUpAmount, setTopUpAmount] = useState('');
  const [processingPayment, setProcessingPayment] = useState(false);
  const [processingSubscription, setProcessingSubscription] = useState({});
  const [subscriptionHistory, setSubscriptionHistory] = useState(null);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user } = useUser();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { fetchCredits } = useCredits();

  const creditOptions = [
    { name: 'Adding a model',name2:'Training a model', credits: 800, icon: CreditAddModelBag },
    { name: 'Create a shot',name2:'Generate an image', credits: 7, icon: CreatShotCreditSystem },
    { name: 'Upscale a shot',name2:'Upscale a shot', credits: 30, icon: UpscaleShotCreditSystem },
  ];

  const subscriptionPlans = [
    {
      id: 'P-17U451794R2419509M4HLDAQ',
      name: 'Starter',
      price: 12,
      credits: 1000,
      interval: 'MONTH',
      features: ['1000 credits monthly'],
    },
    {
      id: 'P-7WA05698GW114015SM4IR4WI',
      name: 'Creator',
      price: 50,
      credits: 5000,
      interval: 'MONTH',
      features: ['5000 credits monthly'],
    },
    {
      id: 'P-9XY87654ZW3210987LMNOPQR',
      name: 'Pro',
      price: 240,
      credits: 25000,
      interval: 'MONTH',
      features: ['25000 credits monthly'],
    },
  ];

  const openXPayPopup = fwdUrl => {
    // Open popup window centered on screen
    const width = 500;
    const height = 700;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    const popup = window.open(
      fwdUrl,
      'XPayPopup',
      `width=${width},height=${height},left=${left},top=${top}`
    );

    // Poll for popup closure and URL changes
    const pollTimer = setInterval(() => {
      try {
        // Check if popup is closed
        if (popup.closed) {
          clearInterval(pollTimer);
          localStorage.removeItem('xpay_intent_id');
          localStorage.removeItem('xpay_amount');
          localStorage.removeItem('xpay_type');
          return;
        }

        // Check if URL contains success parameters
        if (
          popup.location.href.includes('subscription-success') ||
          popup.location.href.includes('payment-success')
        ) {
          clearInterval(pollTimer);
          // Close the popup
          popup.close();
          // Navigate in the main window
          window.location.href = popup.location.href;
        }
      } catch (e) {
        // Handle cross-origin errors silently
        // This will occur when the popup is on the payment provider's domain
      }
    }, 500);
  };

  const handleContactUs = () => {
    const calendlyUrl = 'https://calendly.com/hi-foosh/30min';
    window.open(calendlyUrl, '_blank');
  };

  const handleCancelSubscription = async subscriptionId => {
    if (
      !window.confirm(
        'Are you sure you want to cancel your subscription? This action cannot be undone.'
      )
    ) {
      return;
    }

    const loadingToastId = toast.loading('Cancelling subscription...');

    // Store current state for rollback if needed
    const previousSubscriptionState = { ...subscriptionHistory };

    // Optimistically update UI
    setSubscriptionHistory(prev => ({
      ...prev,
      sub1: false,
      sub2: false,
      sub3: false,
      subscription_id: null,
    }));

    try {
      const token = await getToken({ template: 'token' });
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/xpay-cancel`,
        {
          subscription_id: subscriptionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      toast.dismiss(loadingToastId);

      if (response.data.success) {
        toast.success('Subscription cancelled successfully');

        // Refresh subscription history in background
        try {
          const historyResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/subscriptions-history`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );

          if (
            historyResponse.data.success &&
            historyResponse.data.subscription
          ) {
            console.log(
              'Updated subscription history:',
              historyResponse.data.subscription
            );
            setSubscriptionHistory(historyResponse.data.subscription);
            setLoading(false);
          }
        } catch (historyError) {
          console.error(
            'Error fetching updated subscription history:',
            historyError
          );
          toast.warning(
            'Please refresh the page to ensure subscription status is up to date'
          );
          setLoading(false);
        }
      } else {
        // Rollback optimistic update on failure
        setSubscriptionHistory(previousSubscriptionState);
        throw new Error(
          response.data.message || 'Failed to cancel subscription'
        );
      }
    } catch (error) {
      // Rollback optimistic update on error
      setSubscriptionHistory(previousSubscriptionState);

      toast.dismiss(loadingToastId);
      console.error('Error cancelling subscription:', error);
      const errorMessage =
        error.response?.data?.detail ||
        error.message ||
        'Unknown error occurred';
      toast.error(
        `Failed to cancel subscription: ${errorMessage}. Please try again or contact support.`
      );
    }
  };

  useEffect(() => {
    const fetchSubscriptionHistory = async () => {
      // const loadingToastId = toast.loading('Loading subscription details...');

      try {
        const token = await getToken({ template: 'token' });
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/subscriptions-history`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        //toast.dismiss(loadingToastId);

        if (response.data.success && response.data.subscription) {
          console.log('Subscription history:', response.data.subscription);
          setSubscriptionHistory(response.data.subscription);
        }
        setLoading(false);
      } catch (error) {
        // toast.dismiss(loadingToastId);
        console.error('Error fetching subscription history:', error);
        toast.error(
          'Failed to load subscription details. Please refresh the page.'
        );
        setLoading(false);
      }finally{
        setLoading(false);
      }
    };

    fetchSubscriptionHistory();
  }, []);
  const handleTopUpAmountChange = e => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setTopUpAmount(value);
    }
  };

  const calculateCredits = amount => {
    const parsedAmount = parseInt(amount);
    return isNaN(parsedAmount) ? 0 : parsedAmount * 100;
  };

  const handleOneTimePayment = async () => {
    const amount = parseFloat(topUpAmount);
    if (isNaN(amount) || amount < 10) {
      toast.error('Minimum top-up amount is $10');
      return;
    }

    setProcessingPayment(true);

    try {
      const token = await getToken({ template: 'token' });
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/xpay-onetime`,
        {
          amount: Math.round(amount * 100),
          currency: 'USD',
          customerDetails: {
            name: user.fullName,
            email: user.primaryEmailAddress.emailAddress,
          },
          returnUrl: `${window.location.origin}/xpay?returnUrl=true`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      localStorage.setItem('xpay_intent_id', response.data.xIntentId);
      localStorage.setItem('xpay_amount', amount.toString());
      localStorage.setItem('xpay_type', 'oneTime');

      openXPayPopup(response.data.fwdUrl);
    } catch (error) {
      console.error('Error creating XPay payment:', error);
      toast.error('Error creating payment. Please try again.');
    } finally {
      setProcessingPayment(false);
    }
  };

  const handleSubscribe = async plan => {
    setProcessingSubscription({ ...processingSubscription, [plan.id]: true });

    try {
      const token = await getToken({ template: 'token' });

      // Determine if this is an upgrade or new subscription
      const isUpgrade =
        hasActiveSubscription && subscriptionHistory?.subscription_id;
      const endpoint = isUpgrade
        ? '/upgrade-xpay-subscription'
        : '/xpay/subscription/create';

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${endpoint}`,
        {
          amount: plan.price * 100,
          currency: 'USD',
          customerDetails: {
            name: user.fullName,
            email: user.primaryEmailAddress.emailAddress,
          },
          description: `${plan.name} Plan - ${plan.credits} credits monthly`,
          interval: 'MONTH',
          intervalCount: 1,
          cycleCount: 12,
          callbackUrl: `${window.location.origin}/subscription-success?plan_id=${plan.id}`,
          cancelUrl: `${window.location.origin}/billing`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      localStorage.setItem('xpay_intent_id', response.data.xIntentId);
      localStorage.setItem('xpay_amount', plan.price.toString());
      localStorage.setItem('xpay_type', plan.id);

      openXPayPopup(response.data.fwdUrl);
    } catch (error) {
      console.error('Error managing subscription:', error);
      toast.error(
        hasActiveSubscription
          ? 'Error upgrading subscription. Please try again.'
          : 'Error creating subscription. Please try again.'
      );
    } finally {
      setProcessingSubscription({
        ...processingSubscription,
        [plan.id]: false,
      });
    }
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isReturnFromXPay = urlParams.has('returnUrl');
    const xIntentId = localStorage.getItem('xpay_intent_id');
    const amount = localStorage.getItem('xpay_amount');
    const type = localStorage.getItem('xpay_type');

    if (isReturnFromXPay && xIntentId && amount && type) {
      const checkPaymentStatus = async () => {
        try {
          const token = await getToken({ template: 'token' });
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/xpay-payment-status/${xIntentId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );

          const { status, credits_added } = response.data;

          localStorage.removeItem('xpay_intent_id');
          localStorage.removeItem('xpay_amount');
          localStorage.removeItem('xpay_type');

          if (status === 'success') {
            await fetchCredits();

            if (type === 'oneTime') {
              navigate(
                `/payment-success?amount=${amount}&type=${type}&credits=${credits_added}`
              );
            } else {
              const plan = subscriptionPlans.find(p => p.id === type);
              navigate(
                `/subscription-success?plan_id=${type}&credits=${
                  plan?.credits || 0
                }`
              );
            }
          } else {
            navigate('/payment-failure');
          }
        } catch (error) {
          console.error('Error checking payment status:', error);
          toast.error(
            'Failed to verify payment status. Please contact support.'
          );
          navigate('/payment-failure');
        }
      };

      checkPaymentStatus();
    }
  }, [navigate, getToken, fetchCredits, subscriptionPlans]);
  const formatDate = dateString => {
    const date = new Date(dateString); // Convert the ISO string to a Date object
    date.setMonth(date.getMonth() + 1); // Add one month to the date
    const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year
    return `${day}/${month}/${year}`;
  };
  const hasActiveSubscription =
    subscriptionHistory &&
    (subscriptionHistory.sub1 ||
      subscriptionHistory.sub2 ||
      subscriptionHistory.sub3);

  if (loading)
    return (
      <div className="w-full text-xl font-bold flex h-full  justify-center items-center">
        <img className="h-20" src={Loader} alt="loader" />
      </div>
    );
  return (
    <div className="flex flex-col mx-auto p-4 ">
      {hasActiveSubscription && (
        <div className="mb-12 mt-4 ">
          <div className=" flex items-center gap-4   justify-between rounded-lg">
            {/* <div className="w-1/2 flex"> */}
            <div className="  flex  flex-col ">
              <div className="text-lg font-semibold  ">
                {' '}
                Top up more credits
              </div>
              <div className="text-[12px] font-light ">
                Your top up credits never expire
              </div>
            </div>
            <div className="flex flex-col max-w-[20%] relative ">
              <div className="flex w-full font-light items-center   border-[1px] p-2 rounded-lg ">
                <span className="text-2xl mr-2">$</span>
                <input
                  type="text"
                  value={topUpAmount}
                  onChange={handleTopUpAmountChange}
                  className="flex  w-full text-2xl focus:outline-none focus:border-black"
                  placeholder="100"
                />
                {/* <span className="ml-4 text-gray-600">
                  {calculateCredits(topUpAmount)} credits
                </span> */}
              </div>
              {topUpAmount && parseInt(topUpAmount) < 10 && (
                <p
                  className={`text-red-500 text-sm
                     'flex absolute -bottom-10 left-2'
                    `}
                >
                  Minimum amount should be $10
                </p>
              )}
            </div>
            {/* </div> */}

            <div
              onClick={handleOneTimePayment}
              disabled={processingPayment || parseInt(topUpAmount) < 10}
              className={`py-[.7rem] cursor-pointer flex grow justify-center items-center rounded-xl text-lg font-semibold  ${
                parseInt(topUpAmount) >= 10 && !processingPayment
                  ? 'bg-black text-white hover:bg-gray-800'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              {processingPayment
                ? 'Processing...'
                : `Add ${calculateCredits(topUpAmount)} credits`}
            </div>
          </div>
        </div>
      )}

      <div>
        <h2 className="text-lg font-bold mb-2">Monthly Plans </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          {subscriptionPlans.map((plan, index) => {
            const planKey = `sub${index + 1}`;
            const isSubscribed =
              subscriptionHistory && subscriptionHistory[planKey] === true;
            const currentSubscriptionIndex = subscriptionPlans.findIndex(
              (_, i) => subscriptionHistory?.[`sub${i + 1}`]
            );
            const isUpgrade =
              hasActiveSubscription &&
              !isSubscribed &&
              index > currentSubscriptionIndex;

            return (
              <div
                key={plan.id}
                className={` ${
                  isSubscribed ? 'bg-[#CCFFC7] ' : 'bg-[#00000007]'
                } p-4 rounded-lg `}
              >
                <div className="text-start mb-4">
                  <h3 className="text-sm ">
                    {plan.name} {plan.name === 'Starter' && '📷'}
                    {plan.name === 'Creator' && '🎨'}
                    {plan.name === 'Pro' && '🕶️'}
                  </h3>
                  <div className=" mb-3">
                    ${plan.price}
                    <span className=""> month</span>
                  </div>
                  <div className="text-xl font-bold mb-4">
                    {isSubscribed &&
                      ` ${subscriptionHistory.subscription_credits} / `}
                    {plan.credits} credits
                  </div>
                </div>

                {/* <ul className="mb-6">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center mb-2">
                      <Star className="w-5 h-5 mr-2 text-yellow-500" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul> */}

                {isSubscribed ? (
                  <div>
                    <button
                      className="w-full text-lg leading-[1] py-[.4rem] rounded-lg font-semibold   mb-2 bg-white text-black hover:bg-black hover:text-white"
                      onClick={() => setShowManageModal(true)}
                    >
                      Manage
                      <br />
                      <span className="text-[.75rem] font-light leading-[.75]">
                        Renews on {formatDate(subscriptionHistory.modified_at)}
                      </span>
                    </button>
                    {/* <button
                      onClick={() => setShowManageModal(true)}
                      className="w-full py-2 rounded-lg font-semibold border-2 border-gray-300 text-gray-700 hover:bg-gray-50"
                    >
                      Manage Plan
                    </button> */}
                  </div>
                ) : (
                  <button
                    onClick={() => handleSubscribe(plan)}
                    disabled={processingSubscription[plan.id]}
                    className={`w-full py-3 rounded-lg font-semibold ${
                      processingSubscription[plan.id]
                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                        : isUpgrade
                        ? 'bg-white text-black hover:bg-black hover:text-white'
                        : hasActiveSubscription
                        ? 'bg-white text-black hover:bg-black hover:text-white'
                        : 'bg-white text-black hover:bg-black hover:text-white'
                    }`}
                  >
                    {processingSubscription[plan.id]
                      ? 'Processing...'
                      : isUpgrade
                      ? 'Upgrade Plan'
                      : hasActiveSubscription
                      ? 'Change Plan'
                      : 'Subscribe Now'}
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className=" border-[1px] text- p-4 mt-4 rounded-lg">
        <div className="text-start flex">
          <div className="w-full">
            <h4 className="mb-1 text-sm">ENTERPRISE</h4>
            <div className="flex w-full justify-between ">
              <div className="font-bold text-xl -leading-6">
                Unlock tailored pricing and <br /> unlimited shots for your
                teams.
              </div>
              <div
                className="cursor-pointer flex bg-black text-white px-12 items-center  rounded-lg font-semibold hover:bg-gray-800 transition-colors"
                onClick={handleContactUs}
              >
                Contact Us
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-6 ">
        <div className="font-bold text-lg">How credits work</div>
        <div className="grid grid-cols-3 gap-4 mb-8">
          {creditOptions.map((option, index) => (
            <div key={index} className="flex items-center bg-white p-4 ">
              <img className=" w-16" src={option.icon} alt=""></img>
              <div className=" p-2">
                <p className="text-gray-600 text-sm">{hasActiveSubscription?option.name2:option.name}</p>
                <p className=" font-semibold text-xl">
                  {option.credits} credits
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showManageModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-4">
            <h3 className="text-xl font-semibold mb-4">
              Manage Your Subscription
            </h3>
            <p className="text-gray-600 mb-4">
            For invoices or cancelling a subscription please reach out to us at{' '}
              <a
                href="mailto:support@foosh.ai"
                className="text-blue-600 hover:underline"
              >
                support@foosh.ai
              </a>
            </p>
            <button
              className="bg-black text-white px-4 py-2 rounded w-full hover:bg-gray-800"
              onClick={() => setShowManageModal(false)}
            >
              Okay, got it
            </button>
          </div>
        </div>
      )}

      <div className=" flex items-end text-center justify-center">
        <div className=" flex grow h-[.25px] border-[.25px] mb-2 mr-2 "></div>
        <div className="text-center font-light text-[12px] text-gray-600 mt-8">
          Prices are subject to change at any time. <br /> Get priority support
          on support@foosh.ai
        </div>
        <div className="bg-green-200 h-[.25px] flex grow border-[.25px] mb-2 ml-2"></div>
      </div>
    </div>
  );
};

export default XPayView;
